<template>
    <v-container fluid>
        <v-form @submit.prevent="saveIndividualNotification">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'individual_notification'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ heading }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-tabs v-model="tab" background-color="transparent"
                                        :right="$vuetify.breakpoint.mdAndUp"
                                        :grow="$vuetify.breakpoint.smAndDown"
                                        color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                    <v-tab ref="language" v-for="language in languages" :key="language">
                                        <span :class="exist_translations[language] ? '' : 'red--text '">
                                            {{ language }}
                                        </span>
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="title" rules="required|min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="title" type="text" :disabled="loading"
                                                  :error="!valid" :error-messages="errors"
                                                  prepend-icon="mdi-notification-clear-all"
                                                  :label="$t('push_title')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="body" rules="required|min:2|max:120"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="body" type="text" :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-message-text-outline"
                                                  :label="$t('body')" color="primary" clearable required>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>


                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="subtract_days"
                                                    rules="required|min_value:0|max_value:60"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="subtract_days" :items="subtractDaysItems" :disabled="loading"
                                              :error="!valid" :error-messages="errors"
                                              prepend-icon="mdi-calendar-today"
                                              :label="$t('subtract_days')" color="primary"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>


                            <v-col class="py-0" cols="12" sm="6">
                                <v-menu v-model="onTimeMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="time" name="time" rules="required|min:4|max:5" v-slot="{ errors, valid }">
                                            <v-text-field v-model="time"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" :label="$t('time')"
                                                          prepend-icon="mdi-clock-outline" color="primary" readonly v-on="on"

                                                          clearable @click:clear="time = null"></v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-time-picker ref="picker" v-model="time"
                                                   format="24hr"
                                                   :locale="lang" @change="onTimeMenu = false"></v-time-picker>
                                </v-menu>


                            </v-col>



                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-4">
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xsOnly" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";

export default {
    name: 'IndividualNotificationForm',
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,
            title: null,
            body: null,
            subtract_days: null,
            time: null,
            onTimeMenu: false,

            exist_translations: {},
            all_translations: true,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
        subtractDaysItems() {
            let arr = []
            for (let i = 0; i <= (this.ageTo ? this.ageTo : 60); i++) {
                arr.push(i)
            }
            return arr;
        },
    },

    mounted() {
        this.language = this.languages[this.tab]
        this.checkCreate()
    },
    methods: {
        checkCreate() {
            if (this.$route.name === "individual_notification.create") {
                this.heading = this.$t('individual_notification_creation')
            } else {
                this.heading = this.$t('individual_notification_editing')
                if (this.$route.params.id) {
                    this.getIndividualNotification()
                }
            }
        },
        setLanguage(val) {
            this.language = this.languages[val]
            this.checkCreate()
        },


        async getIndividualNotification() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/individual_notification/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.title = res.body.data.title
                    this.body = res.body.data.body
                    this.subtract_days = res.body.data.subtract_days
                    this.time = res.body.data.time
                    this.exist_translations = res.body.data.exist_translations
                    this.all_translations = res.body.data.all_translations
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_individual_notification'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async saveIndividualNotification() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }
            if (this.title) {
                formData.append('title', this.title)
            }
            if (this.body) {
                formData.append('body', this.body)
            }
            if (this.subtract_days) {
                formData.append('subtract_days', this.subtract_days)
            }
            if (this.time) {
                formData.append('time', this.time)
            }



            if (this.$route.params.id) {
                await this.$http
                    .put(`admin/individual_notification/${this.$route.params.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('individual_notification_has_been_updated'))
                        if (res && res.body && res.body.data && res.body.data.exist_translations) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                        }
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('individual_notification_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/individual_notification', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('individual_notification_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                            this.$router.push({
                                name: 'individual_notification.edit',
                                params: {
                                    id: res.body.data.id
                                }
                            })
                        } else {
                            this.$router.push({
                                name: 'individual_notification'
                            })
                        }
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('individual_notification_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        }
    }
}
</script>
